import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { Stack, Box, Text, Link } from '@rebeldotcom/components';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  urlPrefix: PropTypes.string,
};

const defaultProps = {
  urlPrefix: '',
};

const AccordionSection = ({ items, urlPrefix }) => {
  return (
    <Stack as="ul">
      {items.map((item) => (
        <Box key={item.id} as="li" mb={3}>
          <Link
            as={GatsbyLink}
            color="black"
            id={item.id}
            to={`${urlPrefix}${item.url}`}
          >
            <Text textTransform="capitalize" variant="pound">
              {item.label}
            </Text>
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

AccordionSection.propTypes = propTypes;
AccordionSection.defaultProps = defaultProps;

export { AccordionSection };

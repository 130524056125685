import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text, Stack, redesignTheme } from '@rebeldotcom/components';
import styled, { css } from 'styled-components';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.object,
      ]).isRequired,
      heading: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ContentPane = styled.div`
  overflow: hidden;
  background-color: ${({ theme: { colors } }) =>
    colors ? colors.white : redesignTheme.colors.white};
  width: 100%;
`;

const buttonStyles = css`
  ${({ theme: { space } }) => `
    padding: ${space ? space[3] : redesignTheme.space[3]} ${
    space ? space[4] : redesignTheme.space[4]
  };
  `}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: ${({ theme: { colors } }) =>
    colors ? colors.greys[8] : redesignTheme.colors.greys[8]};
  color: ${({ theme: { colors } }) =>
    colors ? colors.black : redesignTheme.colors.black};

  &:hover,
  &:active {
    background-color: #ccc;
    color: ${({ theme: { colors } }) =>
      colors ? colors.black : redesignTheme.colors.black};
  }
`;

const itemPropTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  heading: PropTypes.string.isRequired,
};

const AccordionItem = ({ heading, content, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack {...rest}>
      <Button
        css={buttonStyles}
        color="black"
        variant="basic"
        display="flex"
        onClick={toggleAccordion}
      >
        <Text fontFamily="title" textAlign="left" variant="megaBold">
          {heading}
        </Text>
      </Button>

      <ContentPane
        overflow="hidden"
        px={3}
        transition={{ opacity: { duration: 0.1 }, height: { duration: 0.2 } }}
        width="100%"
      >
        <Stack bg="greys.8" pb={4} pl={4} pt={1}>
          <Stack pl={2}>{content}</Stack>
        </Stack>
      </ContentPane>
    </Stack>
  );
};

AccordionItem.propTypes = itemPropTypes;

const AccordionPane = ({ items }) => {
  return (
    <Stack as="li">
      {items &&
        items.map(({ heading, content }) => {
          return (
            <AccordionItem key={heading} content={content} heading={heading} />
          );
        })}
    </Stack>
  );
};

AccordionPane.propTypes = propTypes;

export { AccordionPane };

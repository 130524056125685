export const brandAssetsMenu = {
  heading: 'Brand Assets',
  items: [
    { id: 'logo-use', label: 'Logo Use', url: '/logo-use' },
    { id: 'typography', label: 'Typography', url: '/typography' },
    { id: 'colour-guide', label: 'Colour Guide', url: '/colour-guide' },
    { id: 'button-guide', label: 'Button Guide', url: '/button-guide' },
    { id: 'imagery', label: 'Imagery', url: '/imagery' },
    {
      id: 'social-graphics',
      label: 'Social Graphics',
      url: '/social-graphics',
    },
  ],
};

export const componentsMenu = {
  heading: 'Frontend',
  items: [
    {
      id: 'frontend-overview',
      label: 'Overview',
      url: '/overview',
    },
    {
      id: 'pull-requests',
      label: 'Pull Requests',
      url: '/pull-requests',
    },
    {
      id: 'deployments',
      label: 'Deployments',
      url: '/deployments',
    },
    {
      id: 'application-state',
      label: 'Application State',
      url: '/application-state',
    },
    {
      id: 'state-machines',
      label: 'State Machines',
      url: '/state-machines',
    },
    {
      id: 'rebel-cms',
      label: 'CMS',
      url: '/cms',
    },
  ],
};

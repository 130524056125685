import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link as GatsbyLink } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import { css } from 'styled-components';
import {
  ThemeProvider,
  GlobalStyle,
  redesignTheme,
  StyleReset,
  Heading,
  Box,
  Button,
  Stack,
  Grid,
  Icon,
  Text,
  Divider,
} from '@rebeldotcom/components';
import { AccordionPane } from './accordion-pane';
import { AccordionSection } from './accordion-section';
import { brandAssetsMenu, componentsMenu } from './navigation';
import { Code } from '../components/code';
import { Note } from '../components/note';

const components = {
  Box,
  Button,
  Stack,
  Text,
  Heading,
  h1: (props) => <Heading as="h1" {...props} />,
  h2: (props) => <Heading as="h2" {...props} />,
  h3: (props) => <Heading as="h3" {...props} />,
  h4: (props) => <Heading as="h4" {...props} />,
  h5: (props) => <Heading as="h5" {...props} />,
  h6: (props) => <Heading as="h6" {...props} />,
  Divider,
  Section: (props) => <Stack as="section" px={5} py={4} {...props} />,
  Code,
  GatsbyLink,
  Icon,
  Note,
  Grid,
};

const gridCss = css`
  grid-template-columns: 22rem 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header header' 'sidebar main';
`;

const DefaultLayout = ({ children }) => {
  React.useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <ThemeProvider theme={redesignTheme}>
      <Helmet
        meta={[{ name: 'Kronos', content: `Rebel's Design System` }]}
        title="Kronos"
      >
        <html lang="en" />
        <link
          as="font"
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"
          rel="preload"
        />
        <link
          as="font"
          href="https://use.typekit.net/uvp8jtm.css"
          rel="stylesheet"
        />
        <link
          as="font"
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,600;1,300;1,600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <MDXProvider components={components}>
        <GlobalStyle />
        <Grid css={gridCss} minHeight="100vh">
          <Box
            alignItems="center"
            as="header"
            bg="blueDark"
            gridArea="header"
            px={3}
            py={1}
          >
            <GatsbyLink to="/">
              <Box color="white">
                <Icon
                  fill="white"
                  height="8rem"
                  name="simplerebellogonew"
                  width="16rem"
                />
              </Box>
            </GatsbyLink>
          </Box>
          <Box as="nav" width="100%">
            <Stack
              as="ul"
              flex="1"
              bg="greys.8"
              borderRight={2}
              gridArea="sidebar"
            >
              <AccordionPane
                width="100%"
                items={[
                  {
                    heading: brandAssetsMenu.heading,
                    content: (
                      <AccordionSection
                        items={brandAssetsMenu.items}
                        urlPrefix="/brand-assets"
                      />
                    ),
                  },
                  {
                    heading: 'Frontend',
                    content: (
                      <AccordionSection
                        items={componentsMenu.items}
                        urlPrefix="/frontend"
                      />
                    ),
                  },
                  // {
                  //   heading: 'CMS',
                  //   content: (
                  //     <AccordionSection
                  //       items={brandAssetsMenu.items}
                  //       urlPrefix="/kronos"
                  //     />
                  //   ),
                  // },
                ]}
              />
            </Stack>
          </Box>
          <Box as="main" gridArea="main">
            <StyleReset flexDirection="column" fontSize={1} flex="1">
              <Text variant="pound">{children}</Text>
            </StyleReset>
          </Box>
        </Grid>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default DefaultLayout;
